import * as React from "react";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSBreadcrumbs } from "@egds/react-core/breadcrumbs";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";

import { ViewProps } from "../../typings";

export const HotwireView: React.FC<ViewProps> = (props) => {
  const {
    id,
    fmId,
    fmTitleId,
    breadcrumbsWithAnalytics,
    isBreadcrumbsMenuOpen,
    menuAnalytics,
    onBreadcrumbsMenuTriggerClick,
    crossLOBMenuContainer,
  } = props;

  return (
    <div className="BreadcrumbsHotwire" id={id} data-fm={fmId} data-fm-title-id={fmTitleId}>
      <EGDSLayoutFlex justifyContent="space-between" alignItems="center" className="breadcrumbs-alignment">
        <EGDSLayoutFlexItem>
          <Viewport>
            <ViewSmall>
              <EGDSSpacing
                padding={{
                  blockstart: "three",
                  blockend: "three",
                }}
              >
                <EGDSBreadcrumbs
                  crumbs={breadcrumbsWithAnalytics}
                  isOpen={isBreadcrumbsMenuOpen}
                  menuAnalytics={menuAnalytics}
                  onTriggerClick={onBreadcrumbsMenuTriggerClick}
                />
              </EGDSSpacing>
            </ViewSmall>
            <ViewMedium>
              <EGDSSpacing padding="three">
                <EGDSBreadcrumbs crumbs={breadcrumbsWithAnalytics} hasDynamicInlineCrumbs={true} />
              </EGDSSpacing>
            </ViewMedium>
          </Viewport>
        </EGDSLayoutFlexItem>
        {crossLOBMenuContainer}
      </EGDSLayoutFlex>
    </div>
  );
};
