import * as React from "react";
import { useLocation } from "react-router-dom";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSBreadcrumbs } from "@egds/react-core/breadcrumbs";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { BreadcrumbsModule as SharedUIBreadcrumbs } from "../../shared-ui/Breadcrumbs";

import { ViewProps } from "../../typings";

export const DefaultView: React.FC<ViewProps> = (props) => {
  const {
    id,
    fmId,
    fmTitleId,
    breadcrumbsWithAnalytics,
    isBreadcrumbsMenuOpen,
    menuAnalytics,
    onBreadcrumbsMenuTriggerClick,
    crossLOBMenuContainer,
    dynamicInlineCrumbsEligible,
  } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showDebugView = queryParams.get("debug") === "true";

  return (
    <>
      <div className="Breadcrumbs" id={id} data-fm={fmId} data-fm-title-id={fmTitleId}>
        <EGDSLayoutFlex justifyContent="space-between" alignItems="center" className="breadcrumbs-alignment">
          <EGDSLayoutFlexItem>
            <Viewport>
              <ViewSmall>
                <EGDSSpacing padding={{ blockstart: "one" }}>
                  <EGDSBreadcrumbs
                    crumbs={breadcrumbsWithAnalytics}
                    isOpen={isBreadcrumbsMenuOpen}
                    menuAnalytics={menuAnalytics}
                    onTriggerClick={onBreadcrumbsMenuTriggerClick}
                  />
                </EGDSSpacing>
              </ViewSmall>
              <ViewMedium>
                <EGDSSpacing padding={{ block: "two" }}>
                  <EGDSBreadcrumbs
                    crumbs={breadcrumbsWithAnalytics}
                    hasDynamicInlineCrumbs={dynamicInlineCrumbsEligible}
                  />
                </EGDSSpacing>
              </ViewMedium>
            </Viewport>
          </EGDSLayoutFlexItem>
          {crossLOBMenuContainer}
        </EGDSLayoutFlex>
        {showDebugView && (
          <div className="breadcrumbs-alignment">
            <SharedUIBreadcrumbs />
          </div>
        )}
      </div>
    </>
  );
};
