import * as React from "react";
import { AnalyticsConfig } from "@egds/react-core/utils";
import { EGDSLink } from "@egds/react-core/link";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";
import { withStores } from "stores";
import { BreadcrumbsFlexModuleResult } from "typings/microserviceModels/breadcrumbs-flex-module";
import { TravelAdvisory } from "../TravelAdvisory/TravelAdvisory";
import { SchemaOrgScript } from "src/components/utility/SchemaOrg/SchemaOrgScript";
import { CrossLOBMenu } from "components/flexComponents/Breadcrumbs/components/CrossLOBMenu";
import { observer } from "mobx-react";
import { BreadcrumbsProps } from "./typings";
import { HotwireView } from "./components/views/HotwireView";
import { DefaultView } from "./components/views/DefaultView";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { EGDSText } from "@egds/react-core/text";
import { useExperiment } from "@shared-ui/experiment-context";
import type { PageType } from "typings/flexFramework/FlexDefinitions";

const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
const isPageTypeInScope = (pageType: PageType) =>
  ["Flight-Ond-Airport", "Flight-Ond-City", "Travel-Guide-Flights"].includes(pageType);

export const Breadcrumbs = withStores(
  "analytics",
  "flexModuleModelStore",
  "context"
)(
  observer((props: BreadcrumbsProps) => {
    const { context, flexModuleModelStore, analytics, templateComponent } = props;

    const [isBreadcrumbsMenuOpen, setBreadcrumbsMenuOpen] = React.useState(false);

    if (!flexModuleModelStore || !analytics || !templateComponent) {
      return null;
    }

    const { metadata, config } = templateComponent;
    const { id } = metadata;
    const { fmTitleId, view } = config;
    const fmId = getFmId(templateComponent);
    const { exposure } = useExperiment("Search_wizard_only_above_the_fold");
    const dynamicInlineCrumbsEligible = exposure.bucket === 0 || !isPageTypeInScope(context.searchContext.pageType);

    const model = flexModuleModelStore.getModel(id) as BreadcrumbsFlexModuleResult | null;

    if (!model || model.empty || !model.breadcrumbs) {
      return null;
    }

    const breadcrumbs = model.breadcrumbs;
    const onBreadcrumbsMenuTriggerClick = () => {
      setBreadcrumbsMenuOpen(!isBreadcrumbsMenuOpen);
    };

    // add analytics to the breadcrumbs for tracking
    const breadcrumbsWithAnalytics = breadcrumbs.map((crumb, index) => {
      const analyticsConfig: AnalyticsConfig = {
        id: "breadcrumbs",
        callback: (moduleName, description) => {
          const flexTrackingInfo: FlexTrackingInfo = {
            moduleName,
            action: Action.CLICK,
            linkName: description,
          };
          sendDelayedTrackEvent(flexTrackingInfo, analytics);
        },
      };

      return crumb.url ? (
        <EGDSLink inline analytics={analyticsConfig} size="small" key={`breadcrumb-${index}`}>
          <a href={crumb.url}>{capitalize(crumb.text)}</a>
        </EGDSLink>
      ) : (
        <EGDSText inline="small" size={200} aria-current="page">
          {capitalize(crumb.text)}
        </EGDSText>
      );
    });

    // create Strucured Data for breadcrumbs
    const breadcrumbStructuredData = (): string => {
      const itemListElement = breadcrumbs.map((crumb) => {
        const listItem: any = {
          "@type": "ListItem",
          name: crumb.text,
          position: crumb.index,
        };

        if (crumb.fullUrl) {
          listItem["item"] = crumb.fullUrl;
        }

        return listItem;
      });

      const structuredDataObject = {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        itemListElement,
      };

      return JSON.stringify(structuredDataObject);
    };

    const menuAnalytics: AnalyticsConfig = {
      id: "breadcrumbs.menu",
      callback: (moduleName, description) => {
        const flexTrackingInfo: FlexTrackingInfo = {
          moduleName,
          action: Action.CLICK,
          linkName: description,
        };
        sendDelayedTrackEvent(flexTrackingInfo, analytics);
      },
    };

    // find the first breadcrumbs with the dropdown, will be used for the dropdown Plan your trip menu
    const breadcrumbsDropdown = breadcrumbs.find((crumb) => !!crumb.dropDown);

    const crossLOBMenuContainer = breadcrumbsDropdown && (
      <EGDSLayoutFlexItem>
        <CrossLOBMenu items={breadcrumbsDropdown.dropDown} analyticsStore={analytics} />
      </EGDSLayoutFlexItem>
    );

    const viewProps = {
      id,
      fmId,
      fmTitleId,
      breadcrumbsWithAnalytics,
      isBreadcrumbsMenuOpen,
      menuAnalytics,
      onBreadcrumbsMenuTriggerClick,
      crossLOBMenuContainer,
      dynamicInlineCrumbsEligible,
    };

    return (
      <>
        <SchemaOrgScript structuredData={breadcrumbStructuredData()} />
        {view === "hotwire" ? <HotwireView {...viewProps} /> : <DefaultView {...viewProps} />}
        {/* By default, we show TravelAdvisory if LP_SC_Covid19_TravelAdvisory_for_LandingPages is enabled */}
        {!model.hideTravelAdvisory && (
          <EGDSLayoutFlex data-testid="travelAdvisory" className="TravelAdvisory">
            <EGDSLayoutFlexItem>
              <TravelAdvisory context={context} />
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        )}
      </>
    );
  })
);

export default Breadcrumbs;
